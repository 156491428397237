<template>
  <div class="card card-default">
    <div class="card-header bg-info">
      <h3 class="card-title">{{ widget_meta.title }}</h3>

      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>

    <div class="card-body tools" v-show="this.widget.history.length > 1">
      <div class="breadcrumb float-sm-left ml-2 back" @click="cancel" v-show="this.widget.history.length > 1">
        <i class="fas fa-arrow-left"></i>&nbsp;&nbsp;不儲存，回清單
      </div>
      <ol class="breadcrumb float-sm-right mr-2">
        <template v-for="(item, key) in history" v-bind:key="key">
          <!-- item.title 可以呈現html 語法 -->
          <li class="breadcrumb-item link" v-if="key == 0" @click="back_to(key)"><i class="fas fa-home"></i> Home</li>
          <li class="breadcrumb-item" v-else-if="key == history.length - 1" v-show="key != history.length - 1">{{
        item.title }}</li>
          <li class="breadcrumb-item link" v-else @click="back_to(key)">{{ item.title }}</li>
        </template>
      </ol>
    </div>

    <div class="card-body">
      <div class="row">
        <div v-for="field_meta in widget_meta.widget.properties.form_fields" :key="field_meta.variable" :class="[
        'form-group',
        'col-' + field_meta.component.properties.col_width.mobile,
        'col-sm-' + field_meta.component.properties.col_width.pad,
        'col-md-' + field_meta.component.properties.col_width.pad,
        'col-lg-' + field_meta.component.properties.col_width.desktop
      ]">
          <div v-show="field_meta.component.properties.display == 'Y'">
            <component v-bind:is="field_meta._component" v-bind:ref="field_meta.variable"
              v-bind:key="field_meta.variable" v-bind:field="field_meta" v-bind:value="value[field_meta.variable]"
              v-bind:message="message[field_meta.variable]" @input="input_event(field_meta, $event)"></component>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="breadcrumb float-sm-left ml-2 back" @click="cancel" v-show="this.widget.history.length > 1">
        <i class="fas fa-arrow-left"></i>&nbsp;&nbsp;不儲存，回清單
      </div>
      <button type="button" @click="form_save" class="btn btn-primary">儲存</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['widget'],

  data() {

    // 取得元件代碼
    let code = this.widget.widget;
    // 取得 widget 的參數
    let params = this.widget.params;
    // 取得元件資訊
    let current_widget_meta = this.$microlink.widgets[code];
    // 資料初始化
    let localValue = {};
    // 錯誤訊息
    let localMessage = {};
    // 參數的網址字串
    let parameters = '';
    // 將欄位轉換成實體的元件
    let fields = current_widget_meta.widget.properties.form_fields;

    // 表單組件 - 必須指定 form_data 來源
    if (current_widget_meta.widget.properties.events.form_data == undefined ||
      current_widget_meta.widget.properties.events.form_data == '') {
      console.log('尚未指定 form_data 來源');
    }
    else {


      for (let key in fields) {

        // 將欄位轉換成實體的元件
        current_widget_meta.widget.properties.form_fields[key]._component =
          this.$microlink.components[fields[key].component.type].component;

        // 確認登入者是否擁有操作此欄位的權限
        current_widget_meta.widget.properties.form_fields[key].component.properties.display = 'Y';
        let check_result = this.check_field_permission(fields[key]);
        if (check_result == 'read-only') {
          current_widget_meta.widget.properties.form_fields[key].component.properties.to_label = 'Y';
        }
        else if (check_result == 'hide') {
          current_widget_meta.widget.properties.form_fields[key].component.properties.display = 'N';
        }
      }

      if (params == undefined) {
        params = {};
      }

      // 如果有「資料領域」的設定，則加入到參數中
      if (current_widget_meta.widget.properties.datazone_setting != undefined &&
        current_widget_meta.widget.properties.datazone_setting.datazone != undefined &&
        current_widget_meta.widget.properties.datazone_setting.datazone != '') {
        params['dz'] = current_widget_meta.widget.properties.datazone_setting.datazone;
      }


      // 取得網址中的所有參數
      if (current_widget_meta.widget.properties.events.parameters != undefined) {
        let url_params = this.$route.query;
        current_widget_meta.widget.properties.events.parameters.split(',').forEach(function (key) {
          if (url_params[key] != undefined) {
            params[key] = url_params[key];
          }
        });
      }

      // 將 params 物件轉換成網址字串
      // 如果面沒有參數，則不加入 & 字元
      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }

      // 組合 API 網址
      let api_url = this.$microlink.base_api_url + current_widget_meta.widget.properties.events.form_data + parameters;

      // 取得資料
      localValue = this.$microlink.api(api_url);
      if (localValue == [] || localValue == undefined) {
        localValue = {};
        for (let key in fields) {
          localValue[fields[key].variable] = '';
          localMessage[fields[key].variable] = '';
        }
      }
      else {
        for (let key in fields) {
          localMessage[fields[key].variable] = '';
        }
      }


      this.$emit('widgetEvent', { 'event': 'save', 'type': 'form', 'code': code, 'params': params });
    }

    return {
      // 元件資訊
      widget_meta: current_widget_meta,
      // 表單資料
      value: localValue,
      // 錯誤訊息
      message: localMessage,
      // 參數
      parameters: parameters,
      // 歷史紀錄
      history: this.widget.history
    };
  },
  mounted() {
    this.$forceUpdate();
  },
  methods: {
    /**
     * 儲存表單
     */
    form_save() {

      for (let key in this.$refs) {
        if (this.$refs[key][0].validate == undefined) {
          continue;
        }
        this.message[key] = this.$refs[key][0].validate(this.value[key]);
        if (this.message[key] != '') {
          this.$microlink.alert('儲存失敗', '請檢查欄位資料是否正確', 'error');
          return;
        }
      }


      // 重新指定 this
      let $this = this;

      // 組合 API 網址
      let api_url = this.$microlink.base_api_url + this.widget_meta.widget.properties.events.form_save + this.parameters;

      // 發送資料
      this.$microlink.api_post(api_url, this.value, true, function (response) {
        if (response.status == 'failed') {

          // 欄位驗證錯誤
          if (response.type == 'field-validate') {

            // 將錯誤訊息顯示在表單上
            for (var key in response.data) {
              $this.message[key] = response.data[key];
            }

            // 顯示錯誤訊息
            $this.$microlink.alert('儲存失敗', '請檢查欄位資料是否正確', 'error');

          }
          else {
            $this.$microlink.alert('儲存失敗', response.message, 'error');
          }

        }
        else {
          $this.$microlink.alert('儲存成功', '', 'success');
          if ($this.history.length == 1) {
            console.log('設定表單，沒有上一層清單');
          } else {
            // console.log('回清單');
            $this.$emit('widgetEvent', { 'event': 'back', 'step': 1 });
          }
        }

      });

    },

    /**
     * 取消表單
     */
    cancel() {
      if (this.widget.history.length == 1) {
        console.log('設定表單，沒有上一層清單');
      } else {
        // console.log('回清單');
        this.$emit('widgetEvent', { 'event': 'back', 'step': 1 });
      }
    },

    /**
     * 從麵包屑導覽列中，切換到指定的 widget
     */
    back_to: function (history_key) {
      let back_step = (this.widget.history.length - history_key - 1);
      this.$emit('widgetEvent', { 'event': 'back', 'step': back_step });
    },

    /**
     * 輸入事件
     * @param {*} meta 
     * @param {*} $event 
     */
    input_event(meta, $event) {
      this.message[meta.variable] = $event.message == undefined ? '' : $event.message;
      this.value[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;
    },

    /**
     * 確認是否有權限使用這個元件
     */
    check_field_permission: function (field) {
      let permissions = field.component.properties.permissions;
      let user_permissions = (this.$microlink.userinfo == undefined || this.$microlink.userinfo.permission == undefined) ? [] : this.$microlink.userinfo.permission;

      if (permissions == undefined || permissions.length == 0) {
        if (field.component.properties.all_allow_or_deny == undefined || field.component.properties.all_allow_or_deny == '') {
          return 'show';
        }
        else {
          return 'hide';
        }
      }

      if (user_permissions == undefined || user_permissions.length == 0) {
        if (field.component.properties.all_allow_or_deny == undefined || field.component.properties.all_allow_or_deny == '') {
          return 'show';
        }
        else {
          return 'hide';
        }
      }

      // 如果使用者權限中，有包含本欄位的權限，則開始判斷是否有權限
      for (let i = 0; i < permissions.length; i++) {
        if (user_permissions.includes(permissions[i].permission)) {
          // 有權限
          if (permissions[i].action == 'Show') {
            return 'show';
          }
          else if (permissions[i].action == 'Hide') {
            return 'hide';
          }
          else {
            return 'read-only';
          }
        }
      }

      if (field.component.properties.all_allow_or_deny == undefined || field.component.properties.all_allow_or_deny == '') {
        return 'show';
      }
      else {
        return 'hide';
      }
    }
  }
};
</script>

<style scoped>
div.card-footer {
  text-align: right;
}

div.card-footer button {
  margin: 0px 5px;
}

div.tools {
  padding: 10px 0px 0px 1.25rem;
  border: none;
}

.breadcrumb {
  background-color: transparent;
}

.breadcrumb-item.link {
  /* 顯示超連結的顏色 */
  color: #007bff;
  cursor: pointer;
}

/* 將麵包屑間隔的符號由 / 改為 > */
.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}


.breadcrumb.back {
  line-height: 17px;
  padding-left: 2px;
  cursor: pointer;
}
</style>